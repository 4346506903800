import * as React from 'react';

interface ContextProps {
    // initLoaded: boolean;
    screenLoaded: boolean;
    onUpdate(key, value): void;
}

export const InitContext: React.Context<ContextProps> = React.createContext({
    // initLoaded: null,
    screenLoaded: null,
    onUpdate: () => {},
});
