import * as React from 'react';
import $ from './NavBar.styles.scss';
import Logo from '@source/view/svg/Logo';
import { Link, RouteComponentProps } from 'react-router-dom';
import cx from 'classnames';
import Button from '@source/view/components/Button';

interface Props {
    changeRoute(newUrl: string): void;
    currentRoute: string;
    routes: {
        id: string;
        url: string;
        name: string;
    }[];
}

interface State {
    isTop: boolean;
    isActive: boolean;
}

class NavBar extends React.Component<Props, State> {
    state = {
        isTop: true,
        isActive: false,
    };
    
    componentDidMount(): void {
        document.addEventListener('scroll', () => {
            const isTop = window.scrollY < 100;
            if (isTop !== this.state.isTop) {
                this.setState({ isTop });
            }
        });
    }
    
    toggleNav = () => {
        this.setState(prevState => ({
            isActive: !prevState.isActive,
        }));
    }
    
    changeRoute = (newUrl) => {
        this.scrollUp();
        this.toggleNav();
        this.props.changeRoute(newUrl);
    }
    
    scrollUp = async () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
    }

    render() {
        return (
            <div className={cx($.nav, {
                [$.transparentBackground]: this.state.isTop && this.props.currentRoute === '/',
            })}>
                <Link to="/" className={$.logo}>
                    <div className={$.logoText}>
                        <div className={$.logoBig}>
                            KBJ
                        </div>
                        <div className={$.logoSmall}>
                            Montage
                        </div>
                    </div>
                </Link>
                <Button onClick={this.toggleNav} className={$.mobileButton}>
                    {
                        this.state.isActive ?  <i className="fas fa-times" /> : <i className="fas fa-bars" />
                    }
                </Button>
                <ul className={cx($.navGroup, {
                    [$.navGroupActive]: this.state.isActive,
                })}>
                    {
                        this.props.routes.map(route => (
                            <li onClick={() => this.changeRoute(route.url)} className={cx($.navItem, {
                                [$.navItemActive]: this.props.currentRoute === route.url,
                            })} key={route.id}>
                                <Link to={route.url} className={$.navLink}>{route.name}</Link>
                            </li>
                        ))
                    }
                </ul>
            </div>
        );
    }
}

export default NavBar;
