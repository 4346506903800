import uuid from 'uuid/v4';

export interface IconEntity {
    title: string;
    text: string;
    icon: string;
}

export interface IconsModel {
    items: {
        id: string;
        title: string;
        text: string;
        icon: string;
    }[];
}

export function convertIcons(data: IconEntity[]): IconsModel {
    return {
        items: data.map(item => ({
            id: uuid(),
            title: item.title,
            text: item.text,
            icon: item.icon,
        })),
    };
}
