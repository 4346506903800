import * as React from 'react';
import $ from './Content.styles.scss';
import { ContentModel } from '@source/core/models/components/ContentModel';
import Container from '@source/view/components/Container';
import { Link } from 'react-router-dom';
import Button from '@source/view/components/Button';
import cx from 'classnames';

interface Props extends ContentModel{
    buttonLink?: string;
    buttonText?: string;
    type?: 'primary' | 'secondary';
}

const Content: React.FunctionComponent<Props> = (props) => {
    return (
        <Container type={props.type || 'secondary'}>
            <h2 className={cx($.title, {
                [$.isWhite]: props.type === 'secondary' || !props.type,
                [$.isBlue]: props.type === 'primary',
            })}>
                {props.title}
            </h2>
            <p className={cx($.text, {
                [$.isWhite]: props.type === 'secondary' || !props.type,
                [$.isBlue]: props.type === 'primary',
            })}>
                {props.text}
            </p>
            {
                props.buttonText && props.buttonLink && (
                    <div className={$.buttonDiv}>
                        <Button as={Link} to={props.buttonLink} color={props.type === 'primary' ? 'default' : 'white'} className={$.button}>
                            {props.buttonText}
                        </Button>
                    </div>       
                )
            }
        </Container>
    );
};

export default Content;
