export default function validate(name: string, value: string, validation: string) {
    if (!validation) return null;
    switch (validation.trim()) {
        case 'required':
            if (!value) {
                return 'Dit veld is verplicht';
            }
            return null;
        case 'email':
            if (!value) {
                return 'Dit veld is verplicht';
            }
            return value.match(/^([\w.%+-]+)@([\w-]+\.)+([\w]{2,})$/i) ? null : 'Geen geldig email';
    }
}
