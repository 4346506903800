import * as React from 'react';
import $ from './Icons.styles.scss';
import Container from '@source/view/components/Container';
import { IconsModel } from '@source/core/models/components/IconsModel';

interface Props extends IconsModel {
    title: string;
}

const Icons: React.FunctionComponent<Props> = (props) => {
    return (
        <Container>
            <h2 className={$.title}>{ props.title }</h2>
            <div className={$.content}>
                {
                    props.items.map(item => (
                        <div className={$.item} key={item.id}>
                            <div className={$.icon}>
                                <i className={item.icon} />
                            </div>
                            <h3 className={$.itemTitle}>{ item.title }</h3>
                            <p className={$.itemText}>{ item.text } </p>
                        </div>
                    ))
                }
            </div>
        </Container>
    );
};

export default Icons;
