import * as React from 'react';
import $ from './ServiceItem.styles.scss';

interface Props {
    title: string;
    text: string;
    backgroundUrl: string;
}

const ServiceItem: React.FunctionComponent<Props> = (props) => {
    return (
        <div className={$.container} style={{ backgroundImage: `url('${props.backgroundUrl}')` }}>
            <div className={$.mask} />
            <div className={$.content}>
                <h3 className={$.title}>{ props.title }</h3>
                <p className={$.text}>{ props.text }</p>
            </div>
        </div>
    );
};

export default ServiceItem;
