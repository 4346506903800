import { convertGrid, GridEntity, GridModel } from '@source/core/models/components/GridModel';
import { ContentEntity, ContentModel, convertContent } from '@source/core/models/components/ContentModel';

export interface PortfolioEntity {
    
}

export interface PortfolioModel {
    grid: GridModel;
}

export function convertPortfolio(data: GridEntity[]):PortfolioModel {
    return {
        grid: convertGrid(data),
    };
}
