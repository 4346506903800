import uuid from 'uuid/v4';

export interface LogoEntity {
    image: string;
    url: string;
}

export interface LogosModel  {
    items: {
        id: string;
        imageUrl: string;
        linkUrl: string;
    }[];
}

export function convertLogos(data: LogoEntity[]):LogosModel {
    return {
        items: data.map(item => ({
            id: uuid(),
            imageUrl: item.image,
            linkUrl: item.url,
        })),
    };
}
