import * as React from 'react';
import { fetchPage } from '@source/core/services/api-services';
import { Redirect } from 'react-router';
import Title from '@source/view/components/Title';
import BasePage from '@source/view/components/BasePage';
import Diensten from '@source/view/components/Diensten';
import { convertDienstenPage, DienstenPageModel } from '@source/core/models/pages/DienstenModel';
import Content from '@source/view/components/Content';
import { InitContext } from '@source/constants/context';
import Loader from '@source/view/components/Loader';
import InitLoader from '@source/view/components/InitLoader';

interface State {
    loaded: boolean;
    pending: boolean;
    data: DienstenPageModel | null;
}

class DienstenPage extends React.Component<{}, State> {
    state = {
        loaded: false,
        pending: true,
        data: null,
    };

    static contextType = InitContext;
    context!: React.ContextType<typeof InitContext>;
    
    getData = async () => {
        this.context.onUpdate('screenLoaded', false);
        const response = await fetchPage('/services');
        const data = convertDienstenPage(response.data);
        this.setState({
            data,
            pending: false,
            loaded: !!data,
        });
        this.context.onUpdate('screenLoaded', true);
        this.context.onUpdate('startUrl', true);
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        const { data, pending, loaded } = this.state;
        if (!loaded && !pending) {
            return <Redirect to="/404" />;
        }
        if (!loaded || pending) {
            return null;
        }
        return (
            <BasePage>
                <Title title="Diensten"/>
                <Diensten {...data.diensten}/>
                <Content buttonLink="/contact" buttonText="Neem contact op" title="Contact" text="Heeft u vragen of wilt u een vrijblijvend en opmaat gemaakte offerte aanvragen? Twijfel dan geen moment en neem gelijk contact met ons op!"/>
            </BasePage>
        );
    }
}

DienstenPage.contextType = InitContext;

export default DienstenPage;
