import BackgroundImage from '@source/view/img/header_image.png';

export interface HeaderEntity {
    // BackgroundURL: string;
    // Title: string;
    // SubTitle: string;
    // Text: string;
    // ButtonText: string;
    // ButtonLink: string;
    title: string;
    subtitle: string;
    text: string;
    buttonText: string;
    buttonUrl: string;
}

export interface HeaderModel {
    backgroundUrl: string;
    title: string;
    subtitle: string;
    text: string;
    buttonText: string;
    buttonUrl: string;
}

export function convertHeader(data: HeaderEntity): HeaderModel {
    return {
        ...data,
        backgroundUrl: BackgroundImage,
    };
}
