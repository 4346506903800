import * as React from 'react';
import { hot } from 'react-hot-loader';
import { Redirect, Route, RouteComponentProps, Switch, withRouter } from 'react-router';
import { routes } from '@source/view/routes';
import $ from './App.styles.scss';
import NavBar from '@source/view/components/NavBar';
import { convertInit, InitModel } from '@source/core/models/InitModel';
import Footer from '@source/view/components/Footer';
import { fetchPage } from '@source/core/services/api-services';
import InitLoader from '@source/view/components/InitLoader';
import {
    CSSTransition,
    TransitionGroup
} from 'react-transition-group';
import { InitContext } from '@source/constants/context';

interface Props extends RouteComponentProps {
}

interface State {
    loaded: boolean;
    pending: boolean;
    data: InitModel | null;
    startUrl: boolean;
    screenLoaded: boolean;
}

class App extends React.Component<Props, State> {
    state = {
        loaded: false,
        pending: true,
        data: null,
        startUrl: false,
        screenLoaded: false,
    };

    componentDidMount() {
        this.loadData();
    }
    
    changeRoute = (newUrl: string) => {
        this.props.history.push(newUrl);
    }

    loadData = async () => {
        this.setState({
            pending: true,
        });
        const response = await fetchPage('/init');
        const data = convertInit(response.data);

        this.setState({
            data,
            pending: false,
            loaded: !!data,
        });
    }
    
    onUpdate = (key: 'screenLoaded', value: boolean) => {
        this.setState({ [key]: value });
    }

    render() {
        const { data, pending, startUrl, screenLoaded } = this.state;
        if (pending) {
            return <div className={$.container}><InitLoader/></div>;
        }
        return (
            <InitContext.Provider value={{ screenLoaded: false, onUpdate: this.onUpdate }}>
                <div className={$.container}>
                    <main>
                        {
                            startUrl && <NavBar changeRoute={this.changeRoute} currentRoute={this.props.location.pathname} routes={data.routes}/> 
                        }
                        {
                            (pending || !screenLoaded) && <InitLoader/>
                        }
                        <Switch>
                            {
                                routes.map(route => (
                                    <Route key={route.path} exact {...route} />
                                ))
                            }
                            <Redirect to="/404"/>
                        </Switch>
                        {
                            startUrl && <Footer {...data.footer}/> 
                        }
                    </main>
                </div>
            </InitContext.Provider>
        );
    }
}

export default hot(module)(withRouter(App));
