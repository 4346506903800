import uuid from 'uuid/v4';

import { FooterModel } from '@source/core/models/components/FooterModel';

export interface InitEntity {
    navigation: {
        routes: {
            name: string;
            url: string;   
        }[];
    };
    general: {
        contact: {
            email: string;
            phone: string;
        };
        social: {
            instagram: string;
            facebook: string;
            linkedin: string;
        };   
    };
}

export interface InitModel {
    routes: {
        id: string;
        name: string;
        url: string;
    }[];
    footer: FooterModel;
}

export function convertInit(data: InitEntity): InitModel {
    const routes = data.navigation.routes.map(route => ({
        ...route,
        id: uuid(),
    }));
    
    const { contact, social } = data.general;
    
    return {
        routes,
        footer: {
            routes,
            contact: [
                {
                    id: uuid(),
                    icon: 'fas fa-envelope',
                    text: contact.email,
                    url: `mailto: ${contact.email}`,
                },
                {
                    id: uuid(),
                    icon: 'fas fa-phone',
                    text: contact.phone,
                    url: `tel: ${contact.phone}`,
                },
            ],
            social: [
                {
                    id: uuid(),
                    icon: 'fab fa-facebook',
                    url: social.facebook,
                },
                {
                    id: uuid(),
                    icon: 'fab fa-linkedin',
                    url: social.linkedin,
                },
            ],
        },
    };
}
