import * as React from 'react';
import Button from '@source/view/components/Button';
import ButtonBar from '@source/view/components/ButtonBar';

class StyleGuidePage extends React.Component {
    render() {
        return (
            <>
                <p>StyleGuide</p>
                <ButtonBar>
                    <Button onClick={() => alert('Click')} buttonStyle="solid" color="default">
                        Test Button
                    </Button>
                    <Button onClick={() => alert('Click')} buttonStyle="solid" color="white">
                        Test Button
                    </Button>
                    <Button onClick={() => alert('Click')} buttonStyle="outlined" color="white">
                        Test Button
                    </Button>
                    <Button onClick={() => alert('Click')} buttonStyle="outlined" color="white">
                        Test Button
                    </Button>
                </ButtonBar>
            </>
        );
    }
}

export default StyleGuidePage;
