import * as React from 'react';
import $ from './Container.styles.scss';
import cx from 'classnames';

interface Props {
    type?: 'primary' | 'secondary';
    [key: string]: any;
}

const Container: React.FunctionComponent<Props> = ({ type = 'primary', ...props }) => {
    return (
        <div className={cx($.container, {
            [$.primary]: type === 'primary',
            [$.secondary]: type === 'secondary',
        },                 props.className)} style={props.style || null}>
            <div className={$.content}>
                { props.children }
            </div>
        </div>
    );
};

export default Container;
