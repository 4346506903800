import uuid from 'uuid/v4';

export interface ServiceEntity {
    background: string;
    title: string;
    text: string;
}

export interface ServicesModel {
    items: {
        id: string;
        backgroundUrl: string;
        title: string;
        text: string;
    }[];
}

export function convertServices(data: ServiceEntity[]): ServicesModel {
    return {
        items: data.map(item => ({
            id: uuid(),
            backgroundUrl: item.background,
            title: item.title,
            text: item.text,
        })),
    };
}
