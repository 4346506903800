import 'react-hot-loader';
import * as React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { hydrate } from 'react-dom';
import App from '@source/view/App';

const mountElement = document.getElementById('app');

hydrate(
    <BrowserRouter>
        <App />
    </BrowserRouter>,
    mountElement
);
