import * as React from 'react';
import $ from './SliderItem.styles.scss';

interface Props {
    imageUrl: string;
    linkUrl: string;
}

const SliderItem: React.FunctionComponent<Props> = (props) => {
    return (
        <a href={props.linkUrl} target="_blank" className={$.container}>
            <img src={props.imageUrl} className={$.image} alt=""/>
        </a>
    );
};

export default SliderItem;
