export interface DienstEnity {
    title: string;
    text: string;
    icon: string;
}

export interface DienstenModel {
    items: {
        title: string;
        description: string;
        icon: string;
    }[];
}

export function convertDiensten(data: DienstEnity[]): DienstenModel {
    return {
        items: data.map(item => ({
            title: item.title,
            description: item.text,
            icon: item.icon,
        })),
    };
}
