import { convertHeader, HeaderEntity, HeaderModel } from '@source/core/models/components/HeaderModel';
import { convertIcons, IconEntity, IconsModel } from '@source/core/models/components/IconsModel';
import { convertServices, ServiceEntity, ServicesModel } from '@source/core/models/components/ServicesModel';
import { convertLogos, LogoEntity, LogosModel } from '@source/core/models/components/LogosModel';

export interface HomeEntity {
    header: HeaderEntity;
    icons: IconEntity[];
    services: ServiceEntity[];
    logos: LogoEntity[];
}

export interface HomeModel {
    header: HeaderModel;
    icons: IconsModel;
    services: ServicesModel;
    logos: LogosModel;
}

export function convertHome(data: HomeEntity): HomeModel {
    return {
        header: convertHeader(data.header),
        icons: convertIcons(data.icons),
        services: convertServices(data.services),
        logos: convertLogos(data.logos),
    };
}
