import * as React from 'react';
import $ from './Title.styles.scss';
import Container from '@source/view/components/Container';

interface Props {
    title: string;
}

const Title: React.FunctionComponent<Props> = props => (
    <Container type="secondary" className={$.container}>
        <h1 className={$.title}>
            { props.title }
        </h1>
    </Container>
);

export default Title;
