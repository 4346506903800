import * as React from 'react';
import $ from './Lightbox.styles.scss';
import Button from '@source/view/components/Button';

type Image = {
    imageUrl: string;
    title?: string;
    description?: string;
};

interface Props {
    main: Image;
    hasPrevious: boolean;
    hasNext: boolean;
    onClose();
    onNext();
    onPrevious();
}

interface State {
}

class Lightbox extends React.Component<Props, State> {
    componentDidMount() {
        document.addEventListener('keydown', this.checkKey, false);
    }
    componentWillUnmount() {
        document.removeEventListener('keydown', this.checkKey, false);
    }
    
    checkKey = (e) => {
        switch (e.keyCode) {
            case 27:
                this.props.onClose();
                break;
            case 37:
                if (this.props.hasPrevious) this.props.onPrevious();
                break;
            case 39:
                if (this.props.hasNext) this.props.onNext();
                break;
        }
        if (e.keyCode === 27) {
            this.props.onClose();
        }
    }
    
    checkLocation = (e) => {
        if (e.target.classList.contains($.mask)) {
            this.props.onClose();
        }
    }
    
    render() {
        const { main, onClose, onNext, onPrevious, hasNext, hasPrevious } = this.props;
        
        return (
            <div className={$.mask} onClick={this.checkLocation}>
                <div className={$.container}>
                    <div className={$.header}>
                        <Button className={$.close} onClick={onClose}>
                            <i className="fas fa-times" />
                        </Button>
                    </div>
                    <div className={$.content}>
                        <img alt="" src={main.imageUrl} className={$.image} />
                        <div className={$.description}>
                            <h2 className={$.title}>
                                { main.title }
                            </h2>
                            <p className={$.text} dangerouslySetInnerHTML={{ __html: main.description }} />
                        </div>
                    </div>
                    <div className={$.footer}>
                        {
                            hasPrevious && <Button className={$.button} onClick={onPrevious}><i className={$.arrowLeft} /></Button> 
                        }
                        {
                            hasNext && <Button className={$.button} onClick={onNext}><i className={$.arrowRight} /></Button> 
                        }
                    </div>
                </div>
            </div>
        );
    }
}

export default Lightbox;
