import * as React from 'react';
import $ from './Logos.styles.scss';
import Container from '@source/view/components/Container';
import Slider from '@source/view/components/Slider';
import { LogosModel } from '@source/core/models/components/LogosModel';

interface Props extends LogosModel {
}

const Logos: React.FunctionComponent<Props> = (props) => {
    return (
        <Container>
            <div className={$.container}>
                <Slider items={props.items}/>
            </div>
        </Container>
    );
};

export default Logos;
