import * as React from 'react';
import $ from './Slider.styles.scss';
import cx from 'classnames';
import SliderItem from '@source/view/components/Slider/SliderItem';
import { LogosModel } from '@source/core/models/components/LogosModel';
import uuid from 'uuid/v4';

interface Props extends LogosModel {
}

interface State {
    page: number;
    pages: {
        [key: number]: {
            id: string;
            imageUrl: string;
            linkUrl: string;
        }[] | null;
    };
}

class Slider extends React.Component<Props, State> {
    maxPages;
    perPage;
    
    state = {
        page: 1,
        pages: {},
    };
    
    componentWillMount() {
        if (window.innerWidth <= 480) this.perPage = 2;
        else this.perPage = 5;
        
        this.maxPages = Math.ceil(this.props.items.length / this.perPage);
        this.setState({
            page: 1,
            pages: this.paginate(this.props.items, this.perPage),    
        });
    }
    
    paginate(items, perPage) {
        const R = {};
        if (items.length <= perPage) {
            return {
                1: items,
            };
        }
        let currentPageCount = 1;
        for (let i = 0; i < items.length; i += perPage) {
            R[currentPageCount] = items.slice(i, i + perPage);
            currentPageCount += 1;
        }
        return R;
    }

    changePage = (page) => {
        this.setState({ page });
    }
    
    createIndex = () => {
        const children = [];
        for (let i = 1; i <= this.maxPages; i += 1) {
            children.push(<div onClick={() => this.changePage(i)} className={cx($.circle, {
                [$.circleActive]: i === this.state.page,
            })} key={uuid()} />);
        }
        return children;
    }

    render() {
        const { pages, page } = this.state;
        
        return (
            <div className={$.container}>
                <div className={$.group}>
                    {
                        pages[page].map(item => <SliderItem key={item.id} {...item} />)
                    }
                </div>
                <div className={$.index}>
                    {
                        this.createIndex()
                    }
                </div>
            </div>
        );
    }
}

export default Slider;
