import * as React from 'react';
import $ from './InitLoader.styles.scss';
import LoaderIcon from '@source/view/svg/LoaderIcon';

const InitLoader: React.FunctionComponent = (props) => {
    if (window) {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });   
    }
    return (
        <div className={$.container}>
            <div className={$.loader}>
                <LoaderIcon/>
            </div>
        </div>
    );
};

export default InitLoader;
