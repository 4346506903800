import * as React from 'react';
import Home from './screens/HomePage';
import StyleGuidePage from '@source/view/screens/StyleGuide';
import NotFoundPage from '@source/view/screens/NotFoundPage';
import DienstenPage from '@source/view/screens/DienstenPage';
import ContactPage from '@source/view/screens/ContactPage';
import PortfolioPage from '@source/view/screens/PortfolioPage';

interface Route {
    path: string;
    exact: boolean;
    component: React.ComponentType;
}

export const routes: Route[] = [
    {
        path: '/',
        exact: true,
        component: Home,
    },
    {
        path: '/diensten',
        exact: true,
        component: DienstenPage,
    },
    {
        path: '/portfolio',
        exact: true,
        component: PortfolioPage,
    },
    {
        path: '/contact',
        exact: true,
        component: ContactPage,
    },
    {
        path: '/styleguide',
        exact: true,
        component: StyleGuidePage,
    },
    {
        path: '/404',
        exact: true,
        component: NotFoundPage,
    },
];
