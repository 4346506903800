import * as React from 'react';
import $ from './Diensten.styles.scss';
import Container from '@source/view/components/Container';
import cx from 'classnames';

interface Props {
    items: {
        title: string;
        description: string;
        icon: string;
    }[];
}

const Diensten: React.FunctionComponent<Props> = (props) => {
    const { items } = props;
    return (
            <Container>
                <div className={$.container}>
                    {
                        items.map(item =>
                            <div className={$.item}>
                                <i className={cx($.icon, item.icon)} />
                                <h2 className={$.title}>
                                    { item.title }
                                </h2>
                                <p className={$.text}>
                                    { item.description }
                                </p>
                            </div>
                        )
                    }
                </div>
            </Container>
    );
};

export default Diensten;
