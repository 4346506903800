import * as React from 'react';
import $ from './Services.styles.scss';
import { ServicesModel } from '@source/core/models/components/ServicesModel';
import Container from '@source/view/components/Container';
import ServiceItem from '@source/view/components/Services/ServiceItem';
import Button from '@source/view/components/Button';
import { Link } from 'react-router-dom';

interface Props extends ServicesModel {
    title: string;
    link: string;
}

const Services: React.FunctionComponent<Props> = (props) => {
    if (!props.items) {
        return <></>;
    }
    return (
        <Container type="secondary">
            <h2 className={$.title}>{ props.title }</h2>
            <div className={$.group}>
                { props.items.map(item => <ServiceItem key={item.id} {...item} />) }
            </div>
            <div className={$.buttonDiv}>
                <Button as={Link} to={props.link} color="white" className={$.button}>
                    Meer weergeven
                </Button>
            </div>
        </Container>
    );
};

export default Services;
