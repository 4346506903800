import * as React from 'react';
import $ from './Header.styles.scss';
import Button from '@source/view/components/Button';
import { Link } from 'react-router-dom';
import { HeaderModel } from '@source/core/models/components/HeaderModel';

interface Props extends HeaderModel {
}

const Header: React.FunctionComponent<Props> = (props) => {
    return (
        <div className={$.container}>
            <div style={{ backgroundImage: `url('${props.backgroundUrl}')` }} className={$.background} />
            <div className={$.mask} />
            <div className={$.content}>
                <h2 className={$.subtitle}>{ props.subtitle }</h2>
                <h1 className={$.title}>{ props.title }</h1>
                <p className={$.text}>{ props.text} </p>
                <Button as={Link} to={props.buttonUrl} className={$.button} color="default">{ props.buttonText }</Button>
            </div>
        </div>
    );
};

export default Header;
