import * as React from 'react';
import Title from '@source/view/components/Title';
import BasePage from '@source/view/components/BasePage';
import { InitContext } from '@source/constants/context';
import NotFound from '@source/view/components/NotFound';

interface Props {
}

class NotFoundPage extends React.Component<Props> {
    static contextType = InitContext;
    context!: React.ContextType<typeof InitContext>;
    
    componentDidMount() {
        this.context.onUpdate('screenLoaded', true);
        this.context.onUpdate('startUrl', true);
    }

    render() {
        return (
            <BasePage>
                <NotFound/>
            </BasePage>
        );    
    }
}

NotFoundPage.contextType = InitContext;

export default NotFoundPage;
