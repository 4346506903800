import * as React from 'react';
import $ from './Grid.styles.scss';
import { GridModel } from '@source/core/models/components/GridModel';
import Container from '@source/view/components/Container';
import Button from '@source/view/components/Button';
import Lightbox from '@source/view/components/Lightbox';
// import Carousel from 'nuka-carousel';

interface Props extends GridModel {
}

interface State {
    gridItems: any[];
    maxLoaded: number;
    lightBoxIsOpen: boolean;
    lightBoxKey: number;
}

class Grid extends React.Component<Props, State> {
    state = {
        gridItems: [],
        maxLoaded: 6,
        lightBoxIsOpen: false,
        lightBoxKey: null,
    };
    
    componentDidMount() {
        this.convertToColumns();
    }
    
    convertToColumns = () => {
        const images = this.props.images.concat();
        const output = [];
        let count = 0;
        while (images.length > 1) {
            output[count] = images.splice(0, 3);
            count += 1;
        }
        this.setState({
            gridItems: output,
        });
    }
    
    showMore = () => {
        this.setState(prevState => ({
            maxLoaded: prevState.maxLoaded + 6,  
        }));
    }
    
    showLightBox = (key) => {
        this.setState({
            lightBoxIsOpen: true,
            lightBoxKey: key,
        });
    }
    
    render() {
        const { images } = this.props;
        const { lightBoxIsOpen, lightBoxKey, maxLoaded } = this.state;
        
        return (
            <Container type="secondary" style={{ paddingTop: 0, marginTop: 0 }}>
                <div className={$.container}>
                    {
                        images.map((image, key) => {
                            if (key < maxLoaded) {
                                return (
                                    <div style={{ backgroundImage: `url('${image.imageUrl}')` }} className={$.image} key={image.id} onClick={() => this.showLightBox(key)}>
                                        <div className={$.mask} />
                                    </div>
                                );
                            }
                        })
                    }
                </div>
                {
                    maxLoaded < images.length && (
                        <div className={$.buttonDiv}>
                            <Button className={$.button} onClick={this.showMore} buttonStyle="outlined" color="white">Meer laten zien</Button>
                        </div>
                    )
                }
                {
                    lightBoxIsOpen && (
                        <Lightbox
                            main={images[lightBoxKey]}
                            hasNext={!!(lightBoxKey + 1 < images.length && images[lightBoxKey + 1])}
                            hasPrevious={!!(lightBoxKey - 1 >= 0 && images[lightBoxKey - 1])}
                            onClose={() => this.setState({ lightBoxIsOpen: false, lightBoxKey: null, })}
                            onPrevious={() =>
                                this.setState({
                                    lightBoxKey: (lightBoxKey - 1) % images.length,
                                })
                            }
                            onNext={() =>
                                this.setState({
                                    lightBoxKey: (lightBoxKey + 1) % images.length,
                                })
                            }
                        />
                    )
                }
            </Container>
        );
    }
}

export default Grid;
