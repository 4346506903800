import uuid from 'uuid/v4';

export interface GridEntity {
    image: string;
    title: string | null;
    description: string | null;
}

export interface GridModel {
      images: {
          id: string;
          imageUrl: string;
          title: string | null;
          description: string | null;
      }[];
}

export function convertGrid(data: GridEntity[]):GridModel {
    return {
        images: data.map(item => (
            {
                id: uuid(),
                imageUrl: item.image,
                title: item.title,
                description: item.description,
            }
        )),
    };
}
