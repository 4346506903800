import * as React from 'react';
import $ from './BasePage.styles.scss';
import cx from 'classnames';
import uuid from 'uuid/v4';

interface Props {
    hasPadding?: boolean;
    isLoading?: boolean;
}

const BasePage: React.FunctionComponent<Props> = (props) => {
    return (
        <div key={uuid()} className={cx($.animation, $.container, { [$.hasPadding]: props.hasPadding, [$.loading]: props.isLoading })}>
            { props.children }
        </div>
    );
};

export default BasePage;
