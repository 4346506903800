import * as React from 'react';
import $ from './Button.styles.scss';
import cx from 'classnames';

export type ButtonColors = 'default' | 'white';
export type ButtonStyles = 'solid' | 'outlined';

interface Props {
    className?: string;
    as?: React.ComponentType | string;
    color?: ButtonColors;
    buttonStyle?: ButtonStyles;
    onClick?: (event?: React.SyntheticEvent<HTMLButtonElement>) => void;
    active?: boolean;
    fullwidth?: boolean;
    [key: string]: any;
}

const Button: React.FunctionComponent<Props> = ({ as: ElementType = 'button', className, color = 'default', active = false, buttonStyle = 'solid', ...props }) => {
    const buttonClasses = cx(className, $.button, {
        [$.isSolid]: buttonStyle === 'solid',
        [$.isOutlined]: buttonStyle === 'outlined',
        [$.isDefault]: !color || color === 'default',
        [$.isWhite]: color === 'white',
        [$.fullwidth]: props.fullwidth === true,
        [$.active]: active,
    });
    
    return (
        // @ts-ignore
        <ElementType className={buttonClasses} {...props}>
            { props.children }
        </ElementType>
    );
};

export default Button;
