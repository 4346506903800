import { convertDiensten, DienstenModel, DienstEnity } from '@source/core/models/components/DienstenModel';

export interface DienstenPageEntity {
    services: DienstEnity[];
}

export interface DienstenPageModel {
    diensten: DienstenModel;
}

export function convertDienstenPage(data: DienstenPageEntity): DienstenPageModel {
    return {
        diensten: convertDiensten(data.services),
    };
}
