import * as React from 'react';
import { fetchPage } from '@source/core/services/api-services';
import { convertHome, HomeModel } from '@source/core/models/pages/HomeModel';
import { Redirect } from 'react-router';
import Header from '@source/view/components/Header';
import Icons from '@source/view/components/Icons';
import Loader from '@source/view/components/Loader';
import BasePage from '@source/view/components/BasePage';
import Services from '@source/view/components/Services';
import Logos from '@source/view/components/Logos';
import Content from '@source/view/components/Content';
import { InitContext } from '@source/constants/context';
import InitLoader from '@source/view/components/InitLoader';

interface State {
    loaded: boolean;
    pending: boolean;
    data: HomeModel | null;
}

class HomePage extends React.Component<{}, State> {
    state = {
        loaded: false,
        pending: true,
        data: null,
    };
    
    static contextType = InitContext;
    context!: React.ContextType<typeof InitContext>;
    
    async getData() {
        this.context.onUpdate('screenLoaded', false);
        const response = await fetchPage('/home');
        const data = convertHome(response.data);
        this.setState({
            data,
            pending: false,
            loaded: !!data,
        });
        this.context.onUpdate('screenLoaded', true);
        this.context.onUpdate('startUrl', true);
    }
    
    componentWillMount() {
        this.getData();
    }

    render() {
        const { data, pending, loaded } = this.state;
        if (!loaded && !pending) {
            return <Redirect to="/404" />;
        }
        if (!loaded || pending) {
            return null;
        }
        
        return (
            <BasePage>
                <Header {...data.header} />
                <Icons title="Waarom zou je voor ons moeten kiezen?" {...data.icons} />
                <Services title="Wat bieden wij zoal aan?" link="/diensten" {...data.services}/>
                <Logos {...data.logos} />
                <Content buttonLink="/contact" buttonText="Neem contact op" title="Contact" text="Heeft u vragen of wilt u een vrijblijvend en opmaat gemaakte offerte aanvragen? Twijfel dan geen moment en neem gelijk contact met ons op!"/>
            </BasePage>
        );
    }
}

HomePage.contextType = InitContext;

export default HomePage;
