import * as React from 'react';
import $ from './Contact.styles.scss';
import Validate from '@source/core/utils/validate';
import cx from 'classnames';
import Container from '@source/view/components/Container';
import Button from '@source/view/components/Button';
import { sendEmail } from '@source/core/services/api-services';
import LoaderIcon from '@source/view/svg/LoaderIcon';

type Error = {
    isValid: boolean;
    message: string;
};

interface Props {
}

interface State {
    input: {
        message: string;
        name: string;
        telephone: string;
        email: string;
        checked: boolean;
    };
    errors: {
        message: Error;
        name: Error;
        telephone: Error;
        email: Error;
        checked: Error;
    };
    botDetected: boolean;
    sendEmail: boolean;
    emailHasBeenSend: boolean | null;
    alertTimeout: ReturnType<typeof setTimeout> | null;
}

/**
 * @var validation: { [key: string]: string; } "Validation rules"
 */
const validation = {
    name: 'required',
    email: 'email',
    message: 'required',
};

const defaultState = {
    input: {
        message: '',
        name: '',
        telephone: '',
        email: '',
        checked: false,
    },
    errors: {
        message: {
            isValid: false,
            message: '',
        },
        name: {
            isValid: false,
            message: '',
        },
        telephone: {
            isValid: false,
            message: '',
        },
        email: {
            isValid: false,
            message: '',
        },
        checked: {
            isValid: null,
            message: '',
        },
    },
    botDetected: false,
};

class Contact extends React.Component<Props, State> {
    state = {
        input: {
            message: '',
            name: '',
            telephone: '',
            email: '',
            checked: false,
        },
        errors: {
            message: {
                isValid: false,
                message: '',
            },
            name: {
                isValid: false,
                message: '',
            },
            telephone: {
                isValid: false,
                message: '',
            },
            email: {
                isValid: false,
                message: '',
            },
            checked: {
                isValid: null,
                message: '',
            },
        },
        botDetected: false,
        sendEmail: false,
        emailHasBeenSend: null,
        alertTimeout: null,
    };
    
    componentWillUnmount(): void {
        if (this.state.alertTimeout) clearTimeout(this.state.alertTimeout);
    }

    /**
     * Only difference with checkIfFormValid is that this can have a callback
     *
     * @param callback: () => void
     */
    validateAll = (callback) => {
        if (this.checkIfFormValid()) callback();
    }

    /**
     * Check if all the items are valid
     *
     * @return boolean
     */
    checkIfFormValid = () => {
        // Check if there is an error object where isValid false is
        if (this.state.botDetected) {
            return false;
        }
        const { errors, input } = this.state;
        const validations = {} as { [key: string]: Error };
        let invalid = false;
        
        Object.keys(input).forEach((value) => {
            if (value === 'checked') {
                validations[value] = { isValid: input.checked, message: 'Not Checked' };
                if (!input.checked) {
                    invalid = true;   
                }
            } else {
                const text = input[value];
                const validated = Validate(value, text, validation[value]);
                if (validated !== null) {
                    invalid = true;
                }
                validations[value] = {
                    isValid: validated === null,
                    message: validated || null,
                };
            }
        });
        if (invalid) {
            // @ts-ignore
            this.setState({
                errors: validations,
            });
            return false;
        }
        
        return true;
    }

    /**
     * Sends the data to the API
     */
    send = async () => {
        if (this.checkIfFormValid()) {
            this.setState({
                sendEmail: true,
            });
            const response = await sendEmail(this.state.input);
            this.setState({
                sendEmail: false,
                emailHasBeenSend: response.statusCode === 200,
                alertTimeout: setTimeout(() => this.setState({ emailHasBeenSend: null }), 15000),
            }); 
            
            if (response.statusCode === 200) {
                this.reset();
            }
        }
    }
    
    reset = () => this.setState({
        input: defaultState.input,
        errors: defaultState.errors,
        botDetected: defaultState.botDetected,
    })

    /**
     * Handling the onChange of an input or textarea
     *
     * @param e
     */
    handleChange = (e) => {
        const { name, value } = e.target;
        const checkValidation = Validate(name, value, validation[name]);
        this.setState(prevState => ({
            input: {
                ...prevState.input,
                [name]: value,
            },
            errors: {
                ...prevState.errors,
                [name]: {
                    isValid: !checkValidation,
                    message: checkValidation,
                },
            },
        }));
    }
    
    handleCheckedClick = (e) => {
        this.setState(prevState => ({
            input: {
                ...prevState.input,
                checked: !prevState.input.checked,
            },
        }));
    }
    
    invalidateForm = () => {
        this.setState({
            botDetected: true,
        });
    }
    
    render() {
        const { errors } = this.state;
        return (
            <Container className={$.container}>
                <p className={$.text}>
                    Heeft u vragen of wilt u een vrijblijvend en opmaat gemaakte offerte aanvragen? <br />
                    Twijfel dan geen moment en neem gelijk contact met ons op!
                </p>
                <div className={$.form}>
                    {
                        this.state.emailHasBeenSend !== null ? this.state.emailHasBeenSend === true ?
                            (
                                <div className={cx($.alert, $.success)}>
                                    Uw contact aanvraag is verstuurd!
                                </div>    
                            ) :
                            (
                                <div className={cx($.alert, $.failed)}>
                                    Er is iets misgegaan bij het versturen van uw contact aanvraag. Probeer het later opnieuw of stuur direct een mailtje naar <a href="mailto: info@kbj-montage.nl">info@kbj-montage.nl</a>
                                </div>
                            ) : null
                    }
                    <div className={$.column}>
                        <div className={$.formItem}>
                            <label className={cx($.label, {
                                [$.labelError]: !errors.name.isValid && errors.name.message,
                                [$.labelSuccess]: errors.name.isValid,
                            })} htmlFor="name">
                                Naam *
                            </label>
                            <div className={$.formInput}>
                                <input type="text" id="name" name="name" className={$.input} value={this.state.input.name} onChange={this.handleChange} placeholder="Naam.."/>
                                {
                                    !errors.name.isValid && errors.name.message && <div className={$.inputError}>{errors.name.message}</div>
                                }
                            </div>
                        </div>
                        <div className={$.formItem}>
                            <label className={cx($.label, {
                                [$.labelError]: !errors.telephone.isValid && errors.telephone.message,
                                [$.labelSuccess]: errors.telephone.isValid,
                            })} htmlFor="telephone">
                                Telefoon
                            </label>
                            <div className={$.formInput}>
                                <input type="text" id="telephone" name="telephone" className={$.input} value={this.state.input.telephone} onChange={this.handleChange} placeholder="Telefoon.."/>
                                {
                                    !errors.telephone.isValid && errors.telephone.message && <div className={$.inputError}>{errors.telephone.message}</div>
                                }
                            </div>
                        </div>
                        <div className={$.formItem}>
                            <label className={cx($.label, {
                                [$.labelError]: !errors.email.isValid && errors.email.message,
                                [$.labelSuccess]: errors.email.isValid,
                            })} htmlFor="email">
                                E-mail *
                            </label>
                            <div className={$.formInput}>
                                <input type="email" id="email" name="email" className={$.input} value={this.state.input.email} onChange={this.handleChange} placeholder="E-mail.."/>
                                {
                                    !errors.email.isValid && errors.email.message && <div className={$.inputError}>{errors.email.message}</div>
                                }
                            </div>
                        </div>
                    </div>
                    <div className={$.column}>
                        <div className={$.formItem}>
                            <label className={cx($.label, {
                                [$.labelError]: !errors.message.isValid && errors.message.message,
                                [$.labelSuccess]: errors.message.isValid,
                            })} htmlFor="message">
                                Bericht *
                            </label>
                            <div className={$.formInput}>
                                <textarea
                                    className={$.input}
                                    id="message"
                                    name="message"
                                    onChange={this.handleChange}
                                    placeholder="Type hier uw vraag.."
                                    value={this.state.input.message} />
                                {
                                    !errors.message.isValid && errors.message.message && <div className={$.inputError}>{errors.message.message}</div>
                                }
                            </div>
                        </div>
                        <div className={$.formItemCheck}>
                            <div className={$.formInput}>
                                <input type="checkbox" name="check" className={$.inputCheck} onChange={this.handleCheckedClick} checked={this.state.input.checked}/>
                            </div>
                            <label className={cx($.labelCheck, {
                                [$.labelError]: errors.checked.isValid === false,
                                [$.labelSuccess]: errors.checked.isValid,
                            })} onClick={this.handleCheckedClick}>
                                Hierbij geef ik toestemming dat mijn gegevens
                                worden gebruikt om hierna weer contact
                                met mij op te nemen.
                            </label>
                        </div>
                        <input type="hidden" onChange={this.invalidateForm} />
                    </div>
                    {
                        this.state.sendEmail && (
                            <div className={$.mask}>
                                <div className={$.loader}>
                                    <LoaderIcon/>
                                </div>
                            </div>       
                        )
                    }
                </div>
                <div className={$.buttonDiv}>
                    <Button onClick={() => this.validateAll(this.send)} className={$.button} stylingType="solid" color="default" disabled={this.state.sendEmail}>
                        Verzenden
                    </Button>
                </div>
            </Container>
        );
    }
}

export default Contact;
