import * as React from 'react';
import $ from './ButtonBar.styles.scss';
import cx from 'classnames';

interface Props {
    className?: string;
    [key: string]: any;
}

const ButtonBar: React.FunctionComponent<Props> = (props) => {
    const classNames = cx($.buttonGroup, props.className);
    return (
        <div className={classNames} {...props}>
            {props.children}
        </div>
    );
};

export default ButtonBar;
