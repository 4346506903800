import * as React from 'react';
import $ from './Footer.styles.scss';
import { FooterModel } from '@source/core/models/components/FooterModel';
import { Link } from 'react-router-dom';
import cx from 'classnames';

interface Props extends FooterModel {
}

async function scrollUp() {
    window.scrollTo({
        top: 0,
        behavior: 'smooth',
    });
}

const Footer: React.FunctionComponent<Props> = (props) => {
    return (
        <>
            <div className={$.container}>
                <div className={$.content}>
                    <hr className={$.divider} />
                    <div className={$.group}>
                        <div className={$.item}>
                            <h2 className={$.title}>
                                Navigatie
                                <hr className={$.titleDivider} />
                            </h2>
                            <ul className={$.list}>
                                {
                                    props.routes.map(route =>
                                        <li className={$.listItem} key={route.id}>
                                            <Link to={route.url} onClick={scrollUp} className={$.listLink}>
                                                { route.name }
                                            </Link>
                                        </li>
                                    )
                                }
                            </ul>
                        </div>
                        <div className={$.item}>
                            <h2 className={$.title}>
                                Contact
                                <hr className={$.titleDivider} />
                            </h2>
                            <div className={$.iconList}>
                                {
                                    props.contact.map(item =>
                                        <div className={$.iconItem} key={item.id}>
                                            <div className={$.iconItemIcon}>
                                                <i className={item.icon} />
                                            </div>
                                            <span className={$.iconItemText}>
                                                <a href={item.url} className={$.iconItemLink}>{item.text}</a>
                                            </span>
                                        </div>   
                                    )
                                }
                            </div>
                        </div>
                        <div className={$.item}>
                            <h2 className={$.title}>
                                Social
                                <hr className={$.titleDivider} />
                            </h2>
                            <div className={$.socialList}>
                                {
                                    props.social.map(item => (
                                        <div className={$.socialItem} key={item.id}>
                                            <a href={item.url} target="_blank" className={$.socialItemLink}>
                                                <i className={cx(item.icon, $.socialItemIcon)} />
                                            </a>
                                        </div>
                                    ))
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <div className={$.copyright}>
                <p />
                <p className={$.madeBy}>
                    <a href="https://github.com/tvanverseveld" target="_blank">Made By Thijs van Verseveld</a>
                </p>
            </div>
        </>
    );
};

export default Footer;
