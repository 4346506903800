import * as React from 'react';
import Title from '@source/view/components/Title';
import BasePage from '@source/view/components/BasePage/BasePage';
import Contact from '@source/view/components/Contact';
import { InitContext } from '@source/constants/context';
import DienstenPage from '@source/view/screens/DienstenPage';

class ContactPage extends React.Component {
    
    static contextType = InitContext;
    context!: React.ContextType<typeof InitContext>;
    
    componentDidMount() {
        this.context.onUpdate('screenLoaded', true);
        this.context.onUpdate('startUrl', true);
    }

    render() {
        window.scrollTo({
            top: 0,
            behavior: 'smooth',
        });
        return (
            <BasePage>
                <Title title="Contact"/>
                <Contact/>
            </BasePage>
        );
    }
}

DienstenPage.contextType = InitContext;

export default ContactPage;
