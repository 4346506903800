import * as React from 'react';
import $ from './NotFound.styles.scss';
import Container from '@source/view/components/Container';
import Button from '@source/view/components/Button';
import { Link } from 'react-router-dom';

interface Props {
}

const NotFound: React.FunctionComponent<Props> = (props) => {
    return (
        <Container className={$.container} type="secondary">
            <div className={$.content}>
                <h1 className={$.title}>
                    Whooops..
                </h1>
                <p className={$.text}>
                    Deze pagina kon helaas niet gevonden worden..
                </p>
                <div className={$.buttonDiv}>
                    <Button className={$.button} as={Link} to="/" buttonStyle="solid" color="white">Terug naar Homepagina</Button>
                </div>
            </div>
        </Container>
    );
};

export default NotFound;
