// const apiUrl = 'http://cms.kbj.local/api';
const apiUrl = 'https://cms.kbj-montage.nl/api';
const apiKey = 'kSNoF7ByYghW3xKwTAuq';

async function fetchApi(endpoint: string, params: { method?: 'GET' | 'POST'; data?: {} } = {}) {
    try {
        const response = await fetch(`${apiUrl}${endpoint}?key=${apiKey}`, {
            method: params.method || 'GET',
            headers: params.data ? {
                'Content-Type': 'application/json',
                'Access-Control-Allow-Origin': '*',
            } : undefined,
            body: params.data ? JSON.stringify(params.data) : undefined,
        });

        if (response.status >= 200 && response.status < 300) {
            const data = await response.json();
            return { data: data.original.data, statusCode: response.status };
        }
        
        return {
            data: null,
            statusCode: response.status,
        };
    } catch (err) {
        return {
            data: {
                err,
            },
            statusCode: 500,
        };
    }
}

export async function fetchPage(url) {
    return await fetchApi(url);
}

export async function sendEmail(data) {
    return await fetchApi('/contact-mail', { data, method: 'POST' });
}
