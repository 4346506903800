import * as React from 'react';
import { fetchPage } from '@source/core/services/api-services';
import Title from '@source/view/components/Title';
import Loader from '@source/view/components/Loader';
import { Redirect } from 'react-router';
import BasePage from '@source/view/components/BasePage';
import { convertPortfolio, PortfolioModel } from '@source/core/models/pages/PortfolioModel';
import Grid from '@source/view/components/Grid';
import Content from '@source/view/components/Content';
import { InitContext } from '@source/constants/context';
import InitLoader from '@source/view/components/InitLoader';

interface State {
    loaded: boolean;
    pending: boolean;
    data: PortfolioModel | null;
}

class PortfolioPage extends React.Component<{}, State> {
    state = {
        loaded: false,
        pending: true,
        data: null,
    };

    static contextType = InitContext;
    context!: React.ContextType<typeof InitContext>;

    async getData() {
        this.context.onUpdate('screenLoaded', false);
        const response = await fetchPage('/portfolio');
        const data = convertPortfolio(response.data);
        this.setState({
            data,
            pending: false,
            loaded: !!data,
        });
        this.context.onUpdate('screenLoaded', true);
        this.context.onUpdate('startUrl', true);
    }

    componentDidMount() {
        this.getData();
    }

    render() {
        const { data, pending, loaded } = this.state;
        if (!loaded && !pending) {
            return <Redirect to="/404" />;
        }
        if (!loaded || pending) {
            return null;
        }
        return (
            <BasePage>
                <Title title="Portfolio"/>
                <Grid {...data.grid}/>
                <Content type="primary" buttonLink="/contact" buttonText="Neem contact op" title="Contact" text="Heeft u vragen of wilt u een vrijblijvend en opmaat gemaakte offerte aanvragen? Twijfel dan geen moment en neem gelijk contact met ons op!"/>
            </BasePage>
        );
    }
}

PortfolioPage.contextType = InitContext;

export default PortfolioPage;
